import React from 'react';
import Home from './Home';
import Contact from './Contact';
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
    <Router>
        <div>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact-us" component={Contact} />
        </div>
      </Router>
    </div>
  );
}

export default App;
