import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: '/images/opendxplogo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/contact-us',
          children: [
            {
              children: (
                <span>
                  <p>Contact Us</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3 kf5fwg6os7-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan kf5ft05oa1-editor_css',
        children: 'Hello World_',
        texty: true,
      },
      {
        name: 'slogan~kf581ai6gyo',
        className: 'banner3-slogan kf5fwoukzj-editor_css',
        children: 'Ready for the future?',
        texty: true,
      },
      {
        name: 'name~kf5hvq9oxwe',
        className: 'banner3-name kf58dh6ebjr-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      All open source digital experience platform for
                      enterprise, small business&nbsp;
                    </p>
                    <p>
                      and&nbsp;<span>
                        startups and strengthened by open source community
                      </span>
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner3-button kf58e5fvmeu-editor_css',
        children: (
            'Learn More'
        ),
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3 kf52nh0qirj-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>The OpenDXP Ecosystem</p>
            </span>
          </span>
        ),
        className: 'title-h1 kf5gt3coguk-editor_css',
      },
      {
        name: 'content',
        className: 'title-content kf5gt862p-editor_css',
        children: (
          <span>
            <p>
              Rich customer experiences, driven by intelligent, robust and
              scalable open source technologies integrated together.
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper kfcmxzkagk-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/CustomerDataPlatform.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5gzj2g1xm-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>Customer Data&nbsp;</p>
                            <p>
                              <span>Platform</span>
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5cj2ln1or-editor_css',
            children: (
              <span>
                <p>Gather multi-channel customer data under a single roof</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/digitalassetmanagement.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5h0gexc6j-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Digital Asset</p>
                      <p>Management</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5h2fzklj7-editor_css',
            children: (
              <span>
                <p>Manage, Automate, Publish &amp; Optimize assets at scale</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/contentmanagementsystem.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5h0l3p9od-editor_css',
            children: (
              <span>
                <p>Content Management System</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5h2jpgua-editor_css',
            children: (
              <span>
                <p>The central hub of content for your customers</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/lowcodelayoutbuilder.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5h0pllc0j-editor_css',
            children: (
              <span>
                <span>
                  <p>Low Code Layout</p>
                  <p>Builder</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5h2o5h4eu-editor_css',
            children: (
              <span>
                <p>
                  Liberate your marketing team from technological dependencies
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/marketingautomation.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5h0t1h928-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Marketing</p>
                      <p>Automation</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5h2shfl0j-editor_css',
            children: (
              <span>
                <span>
                  <p>Tailor marketing communications at customer</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/businessintelligence.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5h0xqvij-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Business</p>
                          <p>Intelligence</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5h2wayzcl-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    Analyze customer Data to make informed&nbsp;<span>
                      decisions
                    </span>
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~kf58natxjbh',
        className: 'content3-block kf58vn1sk5u-editor_css',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/consentmanagement.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kf5gak02f5t-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>Consent Management</p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kf5h31pfanh-editor_css',
            children: (
              <span>
                <p>Enable Visitors to share consent on data privacy</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kfcletrqzpk-editor_css',
  },
  OverPack: {
    className: 'home-page content6 kf5csfo1ims-editor_css',
    appear: false,
    always: false,
  },
  textWrapper: {
    className: 'content6-text kfcmog2p7w-editor_css',
    xs: 24,
    md: 10,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Our Mission</p>
          </span>
        ),
        className: 'title-h1 kf5i3t1iuul-editor_css',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <p>
                Our mission is to help businesses leverage the strength of open
                source technologies to push the boundaries of customer
                experience. We work with diverse open communities to develop and
                integrate innovative, flexible, secure and scalable digital
                experience solutions.
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  img: {
    children: '/images/mission.png',
    className: 'content6-img kf5h3yj3fn-editor_css',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            '/images/datasecurity.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title kf5i56emt9n-editor_css',
          children: (
            <span>
              <p>Data Security - A responsibility</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content kfcmz9oy09-editor_css',
          children: (
            <span>
              <p>
                It's the customers who are entrusting us with their information
                and we owe a greater responsibility for its security and
                integrity. That’s why TheOinitiativepenDXP is an initiative with
                a principle of data ownership and centralization by liberating
                the platforms from third party dependencies and putting back the
                control to where it belongs - the business owners.
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            '/images/givingbacktocommunity.png',
        },
        title: {
          className: 'content6-title kf5i5dnjltc-editor_css',
          children: (
            <span>
              <p>Giving back to community</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                TheOpenDXP is also committed to empower the open source
                softwares to innovate with the growing demand of digital
                experience and contribute to its core technologies and make it
                more universally adaptable.
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature8-wrapper kf5ea17e4w9-editor_css',
  },
  page: { className: 'home-page feature8 kf5i6k4j4w9-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1 kf5hyygoq5m-editor_css',
        children: (
          <span>
            <span>
              <p>The OpenDXP Value</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content kf5ebdlpz8-editor_css',
        children: (
          <span>
            <span>
              <p>
                An ambition to fuel the business owners and tech leaders to
                attain freedom over their digital infrastructures
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: '#',
          children: '立即体验',
          className: 'kfclcgeigjt-editor_css',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow kf5ekssanl-editor_css',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image kf5eit4sxk-editor_css',
                    children:
                      'https://www.theopendxp.com/images/allppensource.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kf5h9y6huac-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>&nbsp;All Open Source</p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow kf5ekmstgtm-editor_css',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image kf5ekw3e8s-editor_css',
                    children:
                      'https://www.theopendxp.com/images/novenderlocking.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kf5h9tj9hsf-editor_css',
                    children: (
                      <span>
                        <p>No Vender Locking</p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow kf5ekq3bdv-editor_css',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.theopendxp.com/images/dataownership.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kf5haa3a9rj-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>Data Ownership and security</p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.theopendxp.com/images/platformevolution.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kf5hav48baa-editor_css',
                    children: (
                      <span>
                        <p>Platform Evolution</p>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Teams00DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams0-wrapper kfcmtc4pry-editor_css',
  },
  OverPack: {
    playScale: 0.3,
    className: 'home-page teams0 kfcllho53n-editor_css',
    appear: false,
  },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem kfclmv97h4d-editor_css',
        titleWrapper: {
          className: 'teams0-content-wrapper kfclkca23qh-editor_css',
          children: [
            {
              name: 'title',
              children: (
                <span>
                  <p>Supported by founding partner OpenSense Labs</p>
                </span>
              ),
              className: 'teams0-h1 kfclkklyxl-editor_css',
            },
            {
              name: 'image',
              children:
                'https://www.theopendxp.com/images/founding.png',
              className: 'teams0-image kfclp9fcd8i-editor_css',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              OpenSense Labs is global digital transformation
                              agency that has worked in progression to empower
                              businesses with open source technology. Being an
                              authoritative technology partner, OpenSense Labs
                              have years of experience in contributing and
                              perfecting the open source applications.
                            </p>
                            <p>
                              <span>
                                TheOpenDXP initiative join hands with OpenSense
                                Labs as founding partner.
                              </span>
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'teams0-content kfcloghnxz-editor_css',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <a href="https://opensenselabs.com/" title="OpenSense Labs" target="_blank">opensenselabs.com</a>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'teams0-content kfclxmro3t-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 kfclgdcl5b-editor_css',
    appear: false,
    always: false,
  },
  imgWrapper: { className: 'pricing0-img-wrapper pricing-image-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img kfclbi5ze7b-editor_css',
    name: 'image',
    children:
      '/images/helpusachieveourgoal.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper kf5dptor38g-editor_css',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
              <p>Join hands with contributing organizations to shape the initiative</p>
        ),
        className: 'pricing0-title kf5hbwmowdc-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Inviting the open source supporters across the globe to collaborate in this initiative of a true open source digital experience platform.
            </p>
          </span>
        ),
        className: 'pricing0-content kf5hbm8i1e-editor_css',
      },
      {
        name: 'button',
        children: {
          href: '/contact-us',
          type: 'primary',
          children: (
            <span>
              <p>Reach Us</p>
            </span>
          ),
          className: 'kf5dpyzydsr-editor_css',
        },
      },
    ],
  },
};
export const Footer20DataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: {
    className: 'home-page footer2',
    playScale: 0.05,
    appear: false,
    always: false,
  },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'group',
        children: (
          <span>
            <p>Project supported by founding partner OpenSense Labs</p>
          </span>
        ),
        className: 'copyright-group',
      },
      {
        name: 'image2',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg',
        className: 'copyright-line',
      },
      {
        name: 'copyright',
        children: (
          <span>
            <p>Copyright © 2020 TheOpenDXP</p>
          </span>
        ),
        className: 'copyright-text',
      },
    ],
  },
  links: {
    className: 'links',
    children: [
      {
        name: 'Home',
        href: '/',
        className: 'links-weibo',
        children:
          'https://www.theopendxp.com/images/opendxplogo.png',
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/theopendxp',
        target: '_blank',
        className: 'links-zhihu',
        children:
          'https://www.theopendxp.com/images/Twitter .png',
      },
    ],
  },
};
