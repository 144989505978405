import React from 'react';
import axios from "axios";
import {Row, Card, Col, Result, Spin} from 'antd';
import {SmileOutlined} from '@ant-design/icons';

class ConatactUsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      FirstName: '',
      LastName: '',
      Email: '',
      ContactNumber: '',
      Message: '',
      Company: '',
      isLoading: false,
      formSubmitted: false,
      submissionError: false,
      submissionErrorMessage: '',
      showSubmissionForm: true
    }
  }
  handleOnChange = (event) => {
    event.preventDefault()
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  handleOnSubmit = (e) => {
    e.preventDefault()
    this.setState({isLoading: true})
    const {
      FirstName,
      LastName,
      Email,
      ContactNumber,
      Message,
      Company
    } = this.state;
    const data = {
      FirstName: FirstName,
      LastName: LastName,
      Email: Email,
      ContactNumber: ContactNumber,
      Message: Message,
      Company: Company
    }
    console.log('My Response: ', data)
    axios({
      method: 'post',
      url: '/api/send-email',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }).then(response => {
      console.log('Response:', response)
      if (response.status === 200) {
        this.setState({showSubmissionForm: false, isLoading: false, formSubmitted: true})
      } else {
        this.setState({submissionError: true, submissionErrorMessage: 'Please reload the page and try again', isLoading: false})
      }
    }).catch(error => {
      this.setState({submissionError: true, submissionErrorMessage: 'Error', isLoading: false, showSubmissionForm: false})
      console.log('Error Response:', error.response)
    });
  }
  render() {
    const {showSubmissionForm, isLoading, submissionError, submissionErrorMessage} = this.state;
    return (<div>
      <Row>
        <Col span={12} offset={6}>
          <Card title="Reach Us">
            {
              isLoading
                ? <div className="opendxp-contact-spiner"><Spin/>
                    <h3>Sharing data, with experts!</h3>
                  </div>
                : showSubmissionForm
                  ? <form className="ant-form ant-form-horizontal opendxp-contact-us-form" onSubmit={this.handleOnSubmit}>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-col-6 ant-form-item-label">
                          <label for="FirstName" className="ant-form-item-required opendxp-contact-us-form-reqd">First Name:
                          </label>
                        </div>
                        <div className="ant-col ant-col-16 ant-form-item-control">
                          <input className="ant-input" id="FirstName" name="FirstName" required="required" type="text" onChange={this.handleOnChange}/>
                        </div>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-col-6 ant-form-item-label">
                          <label for="LastName" className="ant-form-item-required opendxp-contact-us-form-reqd">Last Name:
                          </label>
                        </div>
                        <div className="ant-col ant-col-16 ant-form-item-control">
                          <input className="ant-input" id="LastName" name="LastName" required="required" type="text" onChange={this.handleOnChange}/>
                        </div>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-col-6 ant-form-item-label">
                          <label for="Email" className="ant-form-item-required opendxp-contact-us-form-reqd">Email:
                          </label>
                        </div>
                        <div className="ant-col ant-col-16 ant-form-item-control">
                          <input className="ant-input" id="Email" name="Email" type="email" required="required" onChange={this.handleOnChange}/>
                        </div>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-col-6 ant-form-item-label">
                          <label for="ContactNumber">Contact Number:
                          </label>
                        </div>
                        <div className="ant-col ant-col-16 ant-form-item-control">
                          <input className="ant-input" type="tel" id="ContactNumber" name="ContactNumber" onChange={this.handleOnChange}/>
                        </div>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-col-6 ant-form-item-label">
                          <label for="Company" className="ant-form-item-required opendxp-contact-us-form-reqd">Company:
                          </label>
                        </div>
                        <div className="ant-col ant-col-16 ant-form-item-control">
                          <input className="ant-input" id="Company" name="Company" required="required" type="text" onChange={this.handleOnChange}/>
                        </div>
                      </div>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-col-6 ant-form-item-label">
                          <label for="Message">Message:
                          </label>
                        </div>
                        <div className="ant-col ant-col-16 ant-form-item-control">
                          <textarea className="ant-input" id="Message" name="Message" type="text" onChange={this.handleOnChange}/>
                        </div>
                      </div>
                      <div className="ant-row ant-form-item opendxp-contact-us-submit">
                        <button type="submit" onSubmit={this.handleOnSubmit} className="ant-btn ant-btn-primary">
                          <span>Submit Data</span>
                        </button>
                      </div>
                    </form>
                  : submissionError
                    ? <React.Fragment><Result status="error" title="Submission Failed" subTitle="Please reload the page and try resubmitting."/></React.Fragment>
                      :
                      <React.Fragment><Result icon={<SmileOutlined />} title="Great, we have Received your information!"/></React.Fragment>
                      }
          </Card>
        </Col>
      </Row>
    </div>);
  }
}

export default ConatactUsForm;
